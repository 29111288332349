<template>

    <div class="wrapper">
        <div class="animated fadeIn">
            <!-- 판매관리 --------------------------------------------------------------------------------------------->
            <BCard>
                <BCard header-tag="header" footer-tag="footer" class="mb-1" >

                    <div slot="header">
                        <BIconFileEarmarkRuledFill/> <strong> 계산서 </strong>
                        <div class="card-header-actions">
                            <small class="text-muted">판매 리스트</small>
                        </div>
                    </div>

                    <BRow class="mb-2">
                        <BCol sm="8">
                            <BInputGroup size="sm">
                                <BInputGroupPrepend>
                                    <BFormSelect size="sm"
                                                 v-model="dateGbn"
                                                 :options="dateGbnOpt"
                                                 @input="setDateGbn"/>
                                </BInputGroupPrepend>
                                <BInputGroupAppend>
                                    <BFormInput v-model="fromDate"
                                                type="date"
                                                 size = "sm"
                                                 @input="setPayDate"/>
                                </BInputGroupAppend>

                                <BInputGroupAppend>
                                    <BFormInput v-model="toDate"
                                                type="date"
                                                size="sm"/>
                                </BInputGroupAppend>

                                <BInputGroupAppend>
                                    <BFormSelect v-model="taxStat"
                                                type="date"
                                                size="sm"
                                                :options="taxStatSelects"
                                                 @input="setTaxStat"/>
                                </BInputGroupAppend>
                                <BInputGroupAppend>
                                    <BFormSelect size="sm" class="ml-3"
                                                 v-model="searchField"
                                                 :options="searchOpts"/>
                                </BInputGroupAppend>
                                <BInputGroupAppend>
                                    <BFormInput size="sm"
                                                v-model="searchWord"
                                                type="text"
                                                @keyup.enter="getSalesOrderList"/>
                                </BInputGroupAppend>
                            </BInputGroup>

                        </BCol>

                        <BCol>
                            <BButtonGroup>
                                <BButton size="sm" variant="primary" @click="getSalesOrderList"><BIconArrowRepeat/> Refresh</BButton>
                                <BButton size="sm" variant="info" @click="exportExcel" class="ml-1"><BIconFileSpreadsheetFill/> 다운로드</BButton>
                            </BButtonGroup>
                        </BCol>

                    </BRow>

                    <BRow>
                        <BCol>
                            <vue-excel-editor
                                    v-model="rows"
                                    ref="taxGrid"
                                    width="100%"
                                    class="mb-1"
                                    :page="numOfRecord"
                                    :readonly-style="{backgroundColor:'#EFE'}"
                                    @update="updateSalesTax"
                                    @select="selectSalesOrder"
                                    :localized-label="$localizedLabel"
                                    filter-row>
                                <vue-excel-column field="ord_no" label="번호" type="number" width="60px"  key-field readonly/>
                                <vue-excel-column field="prd_code" label="제품코드" type="map" width="200px" :options="prdCodeOpts" readonly/>
                                <vue-excel-column field="pay_dt" label="결제일" type="date" width="100px" readonly/>
                                <vue-excel-column field="qty" label="수량" type="number" width="50px" readonly/>
                                <vue-excel-column field="unit_price" label="단가" type="number" width="80px" readonly/>
                                <vue-excel-column field="price" label="금액" type="number" width="80px" readonly/>
                                <vue-excel-column field="pay_amt" label="지불액" type="number" width="80px" readonly/>
                                <vue-excel-column field="pay_type" label="결제" type="map" :options="payTypeOpts" width="90px" readonly/>
                                <vue-excel-column field="comp_no" label="사업자번호" width="110px"/>
                                <vue-excel-column field="comp_name" label="회사명" width="110px"/>
                                <vue-excel-column field="pr_name" label="대표자명" width="100px"/>
                                <vue-excel-column field="biz_kind" label="업태/업종" width="180px"/>
                                <vue-excel-column field="phone_no" label="연락처" width="110px"/>
                                <vue-excel-column field="email" label="e-mail" width="110px"/>
<!--                                <vue-excel-column field="send_stat" label="발송상태" type="map" :options="sendStatOpts" width="80px" readonly/>-->
<!--                                <vue-excel-column field="send_dt" label="발송일시" type="datetime" width="140px" readonly/>-->
                                <vue-excel-column field="pay_stat" label="지불상태"å type="map" :options="payStatOpts" width="80px" readonly/>
                                <vue-excel-column field="tax_stat" label="계산서" type="map" :options="taxStatOpts" width="130px"/>
                                <vue-excel-column field="tax_dt" label="발행일" type="date" width="100px" readonly/>
<!--                                <vue-excel-column field="cncl_yn" label="취소" type="checkYN" width="40px"/>-->
<!--                                <vue-excel-column field="cncl_dt" label="취소일시" type="datetime" width="80px"/>-->
<!--                                <vue-excel-column field="cncl_desc" label="취소사유" width="100px"/>-->
<!--                                <vue-excel-column field="description" label="부가정보" width="140px"/>-->
<!--                                <vue-excel-column field="upd_dt" label="수정일" type="date" width="90px" readonly/>-->
<!--                                <vue-excel-column field="reg_dt" label="등록일" type="date" width="95px" readonly/>-->
                            </vue-excel-editor>
                        </BCol>
                    </BRow>

                    <BRow>
                        <BCol>
                            <BButtonGroup>
                                <BButton variant="danger" @click="makeTaxDocument">
                                    <BIconFileEarmarkSpreadsheetFill/> 계산서 파일생성
                                </BButton>
                            </BButtonGroup>
                        </BCol>

                        <BCol>
                            <BProgress height="2rem" :value="progCount" :max="totalCount" variant="warning" show-value/>
                        </BCol>
                    </BRow>

                </BCard>


            </BCard>

        </div>
    </div>
</template>



<script>
    //-------------------------------------------------------------------------------------------------
    import {
        alertSync,
        alertConfirm,
        apiCall,
        cloneVar,
        checkBisNo,
        toastMsg,
        alertWarn,
        toastSync
    } from '../../../common/utils';
    import qs from 'querystring';
    import moment from "moment";
    import {
        PayTypeOpts, 
        TaxStatOpts, 
        PayStatOpts, 
        SendStatOpts, 
        PayMethods, 
        AcquCodes, 
        BankCodes,
    } from '../../../common/salesType';


    //----------------------------------------------------------------------------------------------------
    export default {
        name: 'ProductToken',
        data () {
            return {
                prdCodeOpts: {},
                selectedPrd: null,
                prdOpts: [],
                prdInfo: {},
                searchOpts: [
                    {value:'name',       text:'이름/회사명'},
                    {value:'comp_name',  text:'회사명'},
                    {value:'email',      text:'전자우편'},
                    {value:'phone_no',   text:'전화번호'},
                    {value:'cu_id',      text:'아이디'},
                    {value:'prd_code',    text:'제품코드'}
                ],
                dayOpts: [
                    {value:7,    text:'1주일'},
                    {value:14,    text:'2주일'},
                    {value:30,    text:'30일'},
                    {value:90,    text:'90일'},
                    {value:180,    text:'180일'},
                    {value:null,    text:'전체'},
                ],
                payTypeOpts: PayTypeOpts,
                taxStat: null,
                taxStatOpts: {
                    '99':'미발행',
                    '01':'영수계산서대상',
                    '02':'청구계산서대상',
                    '03':'현금영수증대상',
                    '04':'영수증대상',
                    '11':'영수발행완료',
                    '12':'청구발행완료',
                    '13':'현금영수증완료',
                    '14':'영수증발행완료'
                },
                taxStatSelects: [
                    {value:null, text:'전체'},
                    {value:'99', text:'미발행'},
                    {value:'01', text:'영수계산서 대상'} ,
                    {value:'02', text:'청구계산서 대상'} ,
                    {value:'03', text:'현금영수증 대상'} ,
                    {value:'04', text:'영수증 대상'},
                    {value:'11', text:'영수계산서발행 완료'},
                    {value:'12', text:'청구계산서발행 완료'} ,
                    {value:'13', text:'현금영수증발행 완료'},
                    {value:'14', text:'영수증발행 완료'}
                    ],
                payStatOpts: PayStatOpts,
                sendStatOpts: SendStatOpts,

                fromDate: moment().format("YYYY-MM-01"),
                toDate: moment(this.fromDate).endOf("month").format("YYYY-MM-DD"),
                statusOpt: {'00':'활성', '10':'준비', '90': '추출', '99':'만료'},
                logTypeOpts: {'I':'접속', 'O':'종료','S':'설치'},
                progCount: 0,
                totalCount: 0,
                paramMap: {},
                rows: [],
                claimLogs: [],
                selectedIdx: null,
                selectedRow: null,
                dateGbn: "pay",
                dateGbnOpt: [
                     {value:'ord', text:'주문일'}
                    ,{value:'appr', text:'승인일'}
                    ,{value:'pay', text:'결제일'}
                    ,{value:'send', text:'전송일'}
                    ,{value:'tax', text:'계산서발행일'}
                    ,{value:'reg', text:'등록일'}
                    ,{value:'upd', text:'수정일'}
                ],

                /////////////////////////////////////////////////////
                searchWord : null,
                searchField: 'name',

                numOfRecord: 20,

            }

        },
        async created(){
            try{
                this.paramMap['dateGbn'] = this.dateGbn;
                this.paramMap['toDate'] = this.toDate;
                this.paramMap['fromDate'] = this.fromDate;
                await this.getPrdCode();
                await this.getSalesOrderList();
            }catch(err){
                console.log(err);
            }
        },
        computed: {

        },

        mounted() {
            console.log("mounted---------------------ProductToken");
            // below is not work!
        },

        methods: {

            async getPrdCode(){
                console.log( "getPrdCode ---- 1" );
                this.prdCodeOpts = {};
                this.prdOpts = [];
                this.prdOpts.push({value:null, text:'주문상품 선택'});
                try{
                    const r = await apiCall('get', `/admin/api/product/info`);

                    r.result.map(i=>{
                        this.prdCodeOpts[i.prd_code] = i.disp_name;
                        this.prdInfo[i.prd_code] = i;
                        this.prdOpts.push({value:i, text: i.prd_seq +' | '+i.disp_name, disabled:(i.use_yn!=="Y")});
                    });
                    console.log( "prdCodeOpts ----> ", this.prdCodeOpts );
                    console.log( "prdOpts ----> ", this.prdOpts );
                    console.log( "prdInfo ----> ", this.prdInfo );

                }catch(err){}
            },


            async getSalesOrderList(){
                this.$refs['taxGrid'].clearFilter();
                let r = null;
                let qry = '';
                if( this.searchWord !== null) this.paramMap[this.searchField] = this.searchWord.toUpperCase();
                qry = qs.stringify( this.paramMap );
                console.log( "query-string ---------- ", qry );
                try{
                    this.rows = [];
                    r = await apiCall('get', `/admin/api/sales/product?${qry}`);
                    console.log( "getSalesOrderList ----------> ", r);
                    if(r.result) this.rows = r.result;

                }catch(err){
                    console.log( err );
                }

            },
            setPayDate(){
                this.toDate =  moment(this.fromDate).endOf("month").format("YYYY-MM-DD");
                this.paramMap['dateGbn'] = this.dateGbn;
                this.paramMap['toDate'] = this.toDate;
                this.paramMap['fromDate'] = this.fromDate;
                this.getSalesOrderList();
            },
            setDateGbn(){
                this.paramMap['dateGbn'] = this.dateGbn;
                this.getSalesOrderList();
            },
            setTaxStat(){
                this.paramMap['tax_stat'] = this.taxStat;
                this.getSalesOrderList();
            },
            exportExcel(){
                const format = 'xlsx';
                const exportSelectedOnly = true;
                const filename = 'product-order-'+moment().format('YYYY-MM-DD');
                this.$refs["taxGrid"].exportTable(format, exportSelectedOnly, filename);
            },

            async selectSalesOrder(idx, evt){

                if(!evt) return;
                // console.log( "selectProductToken --------> ", evt );
                //console.log( "selectSalesOrder --------> ", this.$refs['taxGrid'].getSelectedRecords() );

            },

            /**
             *  세금계산서 파일생성 ***
             * */
            async makeTaxDocument(){
                try{
                    let params = this.$refs['taxGrid'].getSelectedRecords();
                    this.progCount = 0;
                    this.totalCount = params.length;
                    if( !params.length ){
                        await alertWarn( this.$bvModal, {text:"선택한 레코드가 없습니다."});
                        return;
                    }
                    if( params.length > 100){
                        await alertWarn( this.$bvModal, {text:"선택 레코드가 100건을 초과 합니다."});
                        return;
                    }

                    if( !(await alertConfirm(this.$bvModal, `${params.length} 개의 주문에 대하여 계산서 파일을 생성합니다. 진행 하시겠습니까?`, '계산서 파일생성')) ){
                        return;
                    }

                    const r = await apiCall('DOWNLOAD', `/admin/api/sales/tax-register`, params );

                    if( !r.data ){
                        await alertSync(this.$bvModal, {text:`ERROR`});
                        return;
                    }

                    for(let rec of params){
                        const taxCode = rec.tax_stat.slice(-1);
                        rec.tax_stat = "1"+taxCode;
                        rec.tax_dt = (taxCode==='2')? rec.appr_dt:rec.pay_dt; //청구용계산서인 경우 승인일자 기준
                    }

                    const fileURL = window.URL.createObjectURL(new Blob([r.data]));
                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    const fileName = `tax-reg-${moment().format("YYYYMMDDHHmm")}.xlsx`;
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();

                }catch(err){
                    console.log(err);
                }

            },


            validateCell(val, oldVal, rec, field){
                console.log("----------------validateCell-------------------");
                console.log( "validateCell---value ---> ", val );
                console.log( "validateCell---oldVal ---> ", oldVal );
                console.log( "validateCell---rec ---> ", rec );
                console.log( "validateCell---field ---> ", field );
                // console.log( "validateCell---",this.$refs['taxGrid'] );
                // console.log( "validateCell---",this.$refs['taxGrid'].selectRecord(0) );
            },

            async updateSalesTax(rec){
                let rc = rec[0];
                if(!rc.keys[0]){
                    console.log( "nokey" );
                    return;
                }
                const tIndex = this.$refs['taxGrid'].rowIndex[rc.$id];
                console.log( "updateSalesTax--------- rec[0]|rc --->",rc );
                console.log( "updateSalesTax--------- rec[0].$id--->",rc.$id );
                console.log( "updateSalesTax--------- rec[0].$id--->",this.$refs['taxGrid']);
                console.log( "updateSalesTax--------- rowIndex--->",tIndex );
                let row = this.$refs['taxGrid'].table[tIndex];

                console.log( "row ---------> ", row );

                let r = null, msg = null, notice = null;

                try{
                    let param = {};
                    const ordNo = row.ord_no;
                    const fieldName = rc.name;
                    let newVal = rc.newVal;

                    // const row = this.$refs['taxGrid'].currentRecord;

                    if( fieldName==="comp_no" ){
                        const compNo = newVal.replace(/[^0-9]/g,'');
                        console.log( compNo );
                        if( !checkBisNo( compNo ) ){
                            await toastSync( this.$bvToast, `사업자번호가 검증 실패: ${compNo}`, '사업자번호 체크' );
                        }
                        newVal = compNo;
                        row.comp_no = compNo;
                    }

                    param['ord_no'] = ordNo;
                    param[fieldName] = newVal;

                    r = await apiCall("PUT", `/admin/api/sales/product`, param);
                    console.log( r );


                }catch(err){
                    console.log( err );
                }
            },


        }
    }
</script>
